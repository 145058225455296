var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(_vm.editing)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-overline px-0",domProps:{"textContent":_vm._s(_vm.title)}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.roles,"dense":"","outlined":"","disable-lookup":"","hide-selected":"","hide-details":"auto","menu-props":"offsetY","label":"Role"},on:{"change":function () { return _vm.formatCRUD(); }},model:{value:(_vm.editing.role),callback:function ($$v) {_vm.$set(_vm.editing, "role", $$v)},expression:"editing.role"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"dense":"","disable-filtering":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),(_vm.isCustom)?_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"dense":"","label":"Select all","loading":_vm.loading,"value":_vm.selectedAll},on:{"change":_vm.toggleAll}}):_vm._e()],1)]},proxy:true},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.resource.split('-').join(' '))}})]}},{key:"item.create",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['create:own']),callback:function ($$v) {_vm.$set(item, 'create:own', $$v)},expression:"item['create:own']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Own")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['create:any']),callback:function ($$v) {_vm.$set(item, 'create:any', $$v)},expression:"item['create:any']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Any")])])],1)]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['read:own']),callback:function ($$v) {_vm.$set(item, 'read:own', $$v)},expression:"item['read:own']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Own")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['read:any']),callback:function ($$v) {_vm.$set(item, 'read:any', $$v)},expression:"item['read:any']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Any")])])],1)]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['update:own']),callback:function ($$v) {_vm.$set(item, 'update:own', $$v)},expression:"item['update:own']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Own")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['update:any']),callback:function ($$v) {_vm.$set(item, 'update:any', $$v)},expression:"item['update:any']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Any")])])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['delete:own']),callback:function ($$v) {_vm.$set(item, 'delete:own', $$v)},expression:"item['delete:own']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Own")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-checkbox',{attrs:{"disabled":!_vm.isCustom || _vm.loading},on:{"input":function () { return _vm.formatCRUD(); }},model:{value:(item['delete:any']),callback:function ($$v) {_vm.$set(item, 'delete:any', $$v)},expression:"item['delete:any']"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_vm._v("Any")])])],1)]}}],null,true)})],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }