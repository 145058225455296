<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card flat v-if="editing">
        <v-card-title v-text="title" class="text-overline px-0" />

        <v-card-text class="pa-0">
          <v-row>
            <!-- Role select -->
            <v-col cols="12">
              <v-select
                v-model="editing.role"
                :items="roles"
                @change="() => formatCRUD()"
                dense
                outlined
                disable-lookup
                hide-selected
                hide-details="auto"
                menu-props="offsetY"
                label="Role"
              />
            </v-col>

            <!-- CRUD table -->
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"
                :options="options"
                :loading="loading"
                dense
                disable-filtering
                disable-sort
                hide-default-footer
              >
                <template v-slot:top>
                  <v-row no-gutters>
                    <v-spacer />
                    <v-switch dense label="Select all" v-if="isCustom" :loading="loading" :value="selectedAll" @change="toggleAll" class="ma-0 pa-0" />
                  </v-row>
                </template>

                <template v-slot:[`item.resource`]="{item}">
                  <span class="text-capitalize" v-text="item.resource.split('-').join(' ')" />
                </template>

                <template v-slot:[`item.create`]="{item}">
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['create:own']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Own</small>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['create:any']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Any</small>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.read`]="{item}">
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['read:own']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Own</small>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['read:any']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Any</small>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.update`]="{item}">
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['update:own']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Own</small>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['update:any']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Any</small>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.delete`]="{item}">
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['delete:own']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Own</small>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-simple-checkbox :disabled="!isCustom || loading" v-model="item['delete:any']" @input="() => formatCRUD()" />
                    </v-col>
                    <v-col cols="6">
                      <small>Any</small>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import { mapState, mapMutations, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'pibot-form-block-ac',
  validations: {
    editing: {
      role: { required }
    }
  },
  data: () => ({
    title: 'Access Control',
    options: {
      itemsPerPage: -1
    },
    cache: null
  }),
  computed: {
    ...mapState({
      grants: state => state.account.grants,
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    roles () {
      const roles = [
        {
          text: 'Admin',
          value: 'admin'
        },
        {
          text: 'User',
          value: 'user'
        }
      ]

      if (this.$grantAccess(this.$me, 'updateAny', 'superadmin') && this.editing && this.editing._id) {
        roles.push({
          text: 'Custom',
          value: this.editing._id
        })
      }

      return roles
    },
    isCustom () {
      return !!this.editing && (this.editing.role === this.editing._id || this.editing.role.toLowerCase() === 'custom')
    },
    headers () {
      return [
        { text: 'Resource', value: 'resource', divider: true },
        { text: 'Create', value: 'create', divider: true, align: 'center' },
        { text: 'Read', value: 'read', divider: true, align: 'center' },
        { text: 'Update', value: 'update', divider: true, align: 'center' },
        { text: 'Delete', value: 'delete', divider: false, align: 'center' }
      ]
    },
    items () {
      if (!this.grants || !this.editing || !this.editing.role) return []

      const thisGrants = _.cloneDeep(this.grants)
      let userGrants = this.isCustom && this.cache ? this.cache : _.cloneDeep(thisGrants[this.editing.role])

      // Fallback to user role
      if (!userGrants) userGrants = thisGrants.user

      // Merge grants with extension
      if (userGrants.$extend) {
        userGrants.$extend.forEach(role => {
          userGrants = _.merge(thisGrants[role], userGrants)
        })
        delete userGrants.$extend
      }

      const items = Object.keys(userGrants).map(k => ({
        resource: k,
        'create:own': !!userGrants[k]['create:own'],
        'create:any': !!userGrants[k]['create:any'],
        'read:own': !!userGrants[k]['read:own'],
        'read:any': !!userGrants[k]['read:any'],
        'update:own': !!userGrants[k]['update:own'],
        'update:any': !!userGrants[k]['update:any'],
        'delete:own': !!userGrants[k]['delete:own'],
        'delete:any': !!userGrants[k]['delete:any']
      }))

      return items
    },
    selectedAll () {
      const test = !_.some(this.items, (item) => _.some(Object.values(item), (v) => v === false))
      return test
    }
  },
  methods: {
    ...mapMutations({
      setLoadingState: 'settings/SET_LOADING_STATE'
    }),
    ...mapActions({
      fetchUserGrants: 'account/socket_fetchUserGrants'
    }),
    formatCRUD (selectAll) {
      if (!this.isCustom) return

      this.editing.grants = {
        [this.editing._id]: {}
      }

      // Set actions and resources for each which is checked
      this.items.forEach(item => {
        this.editing.grants[this.editing._id][item.resource] = {}
        Object.keys(item).forEach((k) => {
          if (k === 'resource') return

          // First check if we want to manually override the values
          if (typeof selectAll === 'boolean') {
            if (selectAll) this.editing.grants[this.editing._id][item.resource][k] = ['*']
          } else { // If not, proceed as normal
            if (!item[k]) return
            this.editing.grants[this.editing._id][item.resource][k] = ['*']
          }
        })
      })

      this.cache = _.cloneDeep(this.editing.grants[this.editing._id])
    },
    toggleAll (value) {
      this.formatCRUD(!!value)
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    }
  },
  created () {
    // If the custom grants are not yet available, fetch them
    if (this.isCustom && !this.grants[this.editing._id]) {
      this.setLoadingState(true)
      this.fetchUserGrants()
        .finally(() => {
          this.setLoadingState(false)
        })
    }
  }
}
</script>
